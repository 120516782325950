import styled from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, css, IconButton, useTheme } from '@mui/material';

import { GeneralAutocomplete } from 'components/common/GeneralAutocomplete';
import { LabeledTextField, LabeledTextFieldProps } from 'components/common/LabeledTextField';
import { SubmitButtonText } from 'components/common/SubmitButtonText';
import { PipelineErrorBulkActions, UseBulkActionHandlerReturn } from 'hooks/useBulkActionHandler';
import { CssProp } from 'styles/utilityTypes';

/** @jsxImportSource @emotion/react */
interface BulkActionFieldsProps
  extends Pick<
    UseBulkActionHandlerReturn,
    | 'actionInfo'
    | 'isSubmitting'
    | 'selectedRows'
    | 'disableSubmitAction'
    | 'disableActionSelect'
    | 'disableActionField'
    | 'actionTextValue'
    | 'showClearButton'
    | 'handleSetAction'
    | 'handleSetActionText'
    | 'handleClearActionText'
    | 'handleSubmitBulkAction'
  > {
  // selectedRowIds: readonly number[];
  formCss?: CssProp['css'];
}
const ErrorTableActions = Object.values(PipelineErrorBulkActions).map((v) => v.actionName);

export const BulkActionFieldsFields = ({
  formCss,
  selectedRows: selected,
  // handleSetSelectedRows: setSelected,
  disableSubmitAction,
  disableActionSelect,
  disableActionField,
  actionInfo,
  actionTextValue,
  isSubmitting,
  showClearButton,
  handleSetAction,
  handleSetActionText,
  handleClearActionText,
  handleSubmitBulkAction,
}: BulkActionFieldsProps) => {
  // const {
  //   selectedRows: selected,
  //   handleSetSelectedRows: setSelected,
  //   disableSubmitAction,
  //   disableActionField,
  //   actionInfo,
  //   actionTextValue,
  //   showClearButton,
  //   handleSetAction,
  //   handleSetActionText,
  //   handleClearActionText,
  // } = useBulkActionHandler({ actions: PipelineErrorBulkActions,  });

  const theme = useTheme();

  const textFieldEnabledInputProps: LabeledTextFieldProps['InputProps'] = !disableActionField
    ? {
        sx: { paddingTop: theme.spacing(0.75), paddingBottom: theme.spacing(0.75) },
        endAdornment: (
          <IconButton
            size="small"
            css={[{ padding: '0.25rem' }, showClearButton && hiddenClearInputStyle]}
            onClick={handleClearActionText}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }
    : {};

  return (
    <BulkActionFieldsForm onSubmit={handleSubmitBulkAction} css={formCss}>
      <GeneralAutocomplete
        label="Action"
        value={actionInfo?.actionName ?? null}
        // disabled={disableReasonField || isSubmitting}
        // disabled={!selected.length || isSubmitting}
        disabled={disableActionSelect || isSubmitting}
        size="small"
        options={ErrorTableActions}
        onChange={handleSetAction}
        helperText={actionInfo?.actionHelperText}
      />

      <LabeledTextField
        css={disableActionField && inputLabelMarginStyle}
        fullWidth
        size="small"
        value={actionTextValue ?? ''}
        label={actionInfo?.textFieldLabel}
        disabled={disableActionField || isSubmitting}
        onChange={handleSetActionText}
        helperText={disableSubmitAction && !isSubmitting && actionInfo?.textFieldHelperText}
        // Apply prop overrides
        {...actionInfo?.textFieldProps}
        InputProps={{
          ...textFieldEnabledInputProps,
          ...actionInfo?.textFieldProps?.InputProps,
        }}
      />
      {/* <Button disabled={!actionInfo?.name || !actionTextValue || !selected.length} variant="contained"> */}
      <Button type="submit" css={inputLabelMarginStyle} disabled={disableSubmitAction} variant="contained">
        <SubmitButtonText isSubmitting={isSubmitting} />
      </Button>
    </BulkActionFieldsForm>
  );
};

const BulkActionFieldsForm = styled('form')(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    align-items: start;
    align-self: center;

    gap: 1rem;
    padding-top: 1.5rem;
    width: 50%;
  `
);

const hiddenClearInputStyle = css`
  visibility: hidden;
`;

const inputLabelMarginStyle = css`
  margin-top: 1.5rem;
`;
