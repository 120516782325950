/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Dialog, DialogActions, DialogContent, Paper, Stack } from '@mui/material';

import { useServices } from 'services';

import { RefreshButton } from 'components/common/RefreshButton';
import { TooltipButton } from 'components/common/TooltipButton';
import { Text } from 'components/styles';
import { ErrorModalHeadCells, pipelineErrorIsEditable } from 'components/timeline/processStateButton/table/tableUtils';
import { BulkActionStyles, PipelineErrorBulkActions, useBulkActionHandler } from 'hooks/useBulkActionHandler';
import { useResolveError } from 'hooks/useResolveError';
import { ErrorHistoryType, ProcessType, ProcessTypeLabel, TableType } from 'utils/constants';
import { PipelineErrorHistoryModalCustomData, UpdatePipelineErrorsArgs } from 'utils/types';
import { generateRegularProcessingId } from 'utils/utils';

import ErrorHistoryTabs from '../errorHistory/ErrorHistoryTabs';
import { BulkActionFieldsFields } from '../ErrorLogTable/BulkActionFieldsFields';
import ErrorTable from '../ErrorLogTable/ErrorTable';
import PipelineErrorEnhancedTableRow from '../ErrorLogTable/PipelineErrorEnhancedTableRow';
import PipelineErrorTableRow from '../ErrorLogTable/PipelineErrorTableRow';
import { TableHeader } from '../ErrorLogTable/TableHeader';
import { ModalTitle } from './ModalTitle';
import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

interface PipelineErrorHistoryModalProps extends ModalDialogProps {
  showTabs?: boolean;
  showErrorHistory?: boolean;
  hideTitleINC?: boolean;
}

/** @todo Similar code to `GeneralProcessErrorsModal`. Find way to consolidate (see TODO in `GeneralProcessErrorsModal` switch statement) */
export const PipelineErrorHistoryModal = observer(
  ({ modalInfo, onModalClose, showTabs, showErrorHistory, hideTitleINC, ...props }: PipelineErrorHistoryModalProps) => {
    const classes = useModalStyles(props);

    const { processingService } = useServices();

    const {
      pipelineErrorData,
      pipelineErrorPipelineId,
      selectedPipelineId,
      fetchAllOpenErrors,
      updatePipelineErrorHistory,
    } = processingService;

    const { id: modalId, title, subtitle, customModalData } = modalInfo;
    const { defaultTab, showAllOpenErrors } = (customModalData as PipelineErrorHistoryModalCustomData) || {};

    const [currentTab, setCurrentTab] = useState(defaultTab ?? selectedPipelineId?.processType);
    const [isLoading, setIsLoading] = useState(false);
    const [fullScreen, setFullScreen] = useState(false);
    const [refreshOnClose, setRefreshOnClose] = useState(false);

    if (!currentTab) {
      console.error(`Could not find default processType to load. Current value: '${currentTab}'`);
    }

    const { errorList, inc_url } = pipelineErrorData ?? {};
    const pipelineId = pipelineErrorPipelineId;
    const processTypeLabel = currentTab ? ProcessTypeLabel[currentTab] : null;

    // Fetch initial error list
    useEffect(() => {
      if (currentTab) refreshFunction(currentTab);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Only execute once

    const refreshErrorList = (tab?: ProcessType) => {
      const generatedProcessingId = pipelineId ? generateRegularProcessingId(pipelineId) : undefined;
      const errorHistoryArgs = { processType: tab, processingId: generatedProcessingId };

      if (pipelineId) {
        const refreshArgs: UpdatePipelineErrorsArgs = {
          ...pipelineId,
          processType: undefined,
          ...(showErrorHistory && errorHistoryArgs),
          omitHistorical: !showErrorHistory,
        };

        setIsLoading(true);
        updatePipelineErrorHistory(refreshArgs).then(() => setIsLoading(false));
      }
    };

    const refreshAllOpenErrors = (tab?: ProcessType) => {
      setIsLoading(true);
      fetchAllOpenErrors({ processType: tab, skipLoading: true, updateOnly: true }).then(() => setIsLoading(false));
    };

    const refreshFunction = showAllOpenErrors ? refreshAllOpenErrors : refreshErrorList;

    const handleRefresh = () => {
      if (currentTab) refreshFunction(currentTab); // Refresh the current tab
    };

    const handleErrorResolved = () => {
      handleRefresh();
      if (!refreshOnClose) setRefreshOnClose(true); // Raise flag to refresh timeline data on-modal-close
    };

    const handleToggleFullScreen = () => setFullScreen(!fullScreen);

    const handleCloseModal = () => {
      if (onModalClose) onModalClose(modalId);

      // Reload timeline data on-close to refresh the current pipelines' error-statuses
      if (refreshOnClose) processingService.refresh();
    };

    // Bulk-Action variables and handlers
    const { handleBulkResolveError } = useResolveError({
      errorType: ErrorHistoryType.Pipeline,
      onErrorResolved: handleErrorResolved,
    });
    const { bulkActionTitleCss, ...actionHandlerProps } = useBulkActionHandler({
      actions: PipelineErrorBulkActions,
      onBulkAction: ({ text, identifiers }) => handleBulkResolveError({ errorIds: identifiers, comment: text }),
    });
    const { selectedRows: selected, handleSetSelectedRows: setSelected, disableActionSelect } = actionHandlerProps;

    const TitleAdornmentComponent = (
      <Stack direction={'row'} alignItems={'center'} justifyContent={'end'} gap={'1rem'}>
        {!hideTitleINC && (
          <Button
            disabled={!inc_url || isLoading || !processTypeLabel} // Disable if loading or if inc_url doesn't exist
            href={inc_url ?? ''}
            target="_blank"
            endIcon={<OpenInNewIcon />}
            style={{ textTransform: 'unset' }}
          >
            View INC {`(${processTypeLabel})`}
          </Button>
        )}
        <RefreshButton tooltipText="Refresh table" placement="top" onClick={handleRefresh} />
        <TooltipButton
          placement="top"
          onClick={handleToggleFullScreen}
          tooltipText={fullScreen ? 'Minimize' : 'Maximize'}
          Icon={fullScreen ? FullscreenExitIcon : FullscreenIcon}
        />
      </Stack>
    );

    return (
      <Dialog
        open={true}
        className={classes.log}
        fullWidth
        fullScreen={fullScreen}
        maxWidth="xl"
        scroll="paper"
        onClose={handleCloseModal}
      >
        {/* Title */}
        <ModalTitle modalTitle={title} modalSubtitle={subtitle} adornment={TitleAdornmentComponent} />

        {/* Text */}
        <DialogContent>
          <Stack gap=".5rem">
            {/* Pipeline Error Table */}
            {(showTabs || showAllOpenErrors) && (
              <ErrorHistoryTabs
                defaultTab={defaultTab}
                activeTab={currentTab}
                showAllOpenErrors={showAllOpenErrors}
                onChange={(processType) => {
                  refreshFunction(processType);
                  setCurrentTab(processType);
                }}
              />
            )}
            <ErrorTable
              isErrorHistory={showErrorHistory}
              loading={isLoading}
              tableType={TableType.ErrorHistory}
              tableHeaders={TableHeader[TableType.ErrorHistory]}
              errorList={errorList ?? []}
              RowRenderer={PipelineErrorTableRow}
              onErrorResolved={handleErrorResolved}
              newTableHeaders={ErrorModalHeadCells[TableType.PipelineError]}
              EnhancedRowRenderer={PipelineErrorEnhancedTableRow}
              disableTable={actionHandlerProps.isSubmitting}
              defaultSort={{
                sortBy: 'latest_occurrence',
                sortDirection: 'desc',
              }}
              editableRow={{
                isEditableFunc: pipelineErrorIsEditable,
                readOnlyText: 'Resolved errors are not selectable',
              }}
              selectedRowsState={{
                selectedRows: selected,
                setSelectedRows: setSelected,
              }}
            />
            {/* <div css={actionRowStyle}></div> */}

            {/* Error Actions */}
            <Stack css={BulkActionStyles.bulkActionSection}>
              <Text css={bulkActionTitleCss}>
                Bulk Actions {!disableActionSelect && `(${selected.length} selected)`}
              </Text>

              <Paper elevation={2}>
                <div
                  css={[
                    BulkActionStyles.bulkActionField,
                    disableActionSelect ? BulkActionStyles.formDisabled : BulkActionStyles.formEnabled,
                  ]}
                >
                  <BulkActionFieldsFields {...actionHandlerProps} />
                </div>
              </Paper>
            </Stack>
          </Stack>
        </DialogContent>

        {/* Action Buttons */}
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
);
