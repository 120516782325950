/** @jsxImportSource @emotion/react */
import React, { ComponentPropsWithoutRef, ReactNode } from 'react';

import { observer } from 'mobx-react';

import { css, Stack, TableRowProps, Theme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

import { EnhancedTable, EnhancedTableProps } from 'components/timeline/processStateButton/table/EnhancedTable';
import { PipelineErrorWithAction } from 'components/timeline/processStateButton/table/tableUtils';
import { BaseEnhanceTableData } from 'components/timeline/processStateButton/table/types';
import { TableType } from 'utils/constants';
import { ErrorHistory, PipelineLogError } from 'utils/types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
}));

export type ErrorTableRowRendererMiscProps = {
  isErrorHistory?: boolean;
  onErrorResolved?: () => void;
};

export type ErrorTableRowRendererProps<T extends PipelineLogError | ErrorHistory> = TableRowProps &
  ErrorTableRowRendererMiscProps & {
    errorInfo: T;
  };

type EnhancedPipelineErrorTableData = (PipelineLogError | ErrorHistory) & // TODO: Remove usages of `ErrorHistory`; all error dialogs are based on `PipelineLogError`
  PipelineErrorWithAction &
  BaseEnhanceTableData;

// export interface ARMFlowTableProps<T extends PipelineLogError | ErrorHistory> extends ComponentPropsWithoutRef<'div'> {
export interface ARMFlowTableProps<T extends EnhancedPipelineErrorTableData> extends ComponentPropsWithoutRef<'div'> {
  tableType: TableType;
  tableHeaders: ReactNode[];

  newTableHeaders?: EnhancedTableProps<T>['headers'];
  selectedRowsState?: Partial<EnhancedTableProps<T>>['selectedRowsState'];
  EnhancedRowRenderer?: EnhancedTableProps<T>['RowRenderer'];
  editableRow?: EnhancedTableProps<T>['editable'];
  disableTable?: EnhancedTableProps<T>['disableTable'];
  defaultSort?: {
    sortBy?: EnhancedTableProps<T>['defaultSortBy'];
    sortDirection?: EnhancedTableProps<T>['defaultSortDirection'];
  };
  /** @deprecated replaced w/ prop in `HeadCell`  */
  enhancedColumnProps?: Partial<EnhancedTableProps<T>>['columnProps'];

  errorList: T[];
  RowRenderer: (props: ErrorTableRowRendererProps<T>) => React.ReactElement;
  onErrorResolved?: ErrorTableRowRendererProps<T>['onErrorResolved'];

  loading?: boolean;
  /** @deprecated prop not currently used */
  isErrorHistory?: boolean;
  /** @deprecated prop not currently used */
  extraComponent?: ReactNode;
}

const ErrorTable = observer(
  <T extends EnhancedPipelineErrorTableData>({
    tableType,
    tableHeaders: headers,
    newTableHeaders,
    selectedRowsState,
    EnhancedRowRenderer,
    editableRow,
    disableTable,
    defaultSort,
    enhancedColumnProps,
    errorList,
    RowRenderer,
    onErrorResolved,
    loading = false,
    isErrorHistory,
    extraComponent,
    ...props
  }: ARMFlowTableProps<T>): React.ReactElement | null => {
    const { className } = props;

    const classes = useStyles(props);

    return (
      // TODO: revert `Stack` to `div` when I finally remove original table components
      <Stack gap={'1rem'} className={clsx(classes.root, className)} {...props}>
        {extraComponent}

        {/* TODO: Integrate error-resolved props */}
        {newTableHeaders && (
          <EnhancedTable<T>
            data={errorList}
            headers={newTableHeaders}
            loading={loading}
            editable={editableRow}
            disableTable={disableTable}
            defaultSortBy={defaultSort?.sortBy}
            defaultSortDirection={defaultSort?.sortDirection}
            RowRenderer={EnhancedRowRenderer}
            columnProps={enhancedColumnProps}
            selectedRowsState={selectedRowsState}
          />
        )}
      </Stack>
    );
  }
);

export default ErrorTable;

const noLogsStyle = css`
  background-color: ${grey[900]};
  text-align: center;
  padding: 2rem 0;
  text-transform: capitalize;
`;
