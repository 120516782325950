/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { capitalize, zip } from 'lodash';

import { ErrorModalHeadCells } from 'components/timeline/processStateButton/table/tableUtils';
import { BaseEnhancedTableRowRendererProps } from 'components/timeline/processStateButton/table/types';
import { useResolveError } from 'hooks/useResolveError';
import { ErrorHistoryType, TableType } from 'utils/constants';
import { PipelineIdentifier, PipelineLogError } from 'utils/types';

import ActionsCell from './ActionsCell';
import { EnhancedErrorTableCell } from './EnhancedErrorTableCell';
import { ErrorTableRowRendererMiscProps } from './ErrorTable';
import LongText from './LongText';

type PipelineErrorEnhancedTableRowProps = ErrorTableRowRendererMiscProps &
  BaseEnhancedTableRowRendererProps<PipelineLogError>;

const PipelineErrorEnhancedTableRow = observer(
  ({
    isErrorHistory,
    onErrorResolved,

    rowData: errorInfo,
    disabled = false,

    customPropsSelector,
    firstColumnProps = {},
    ...props
  }: PipelineErrorEnhancedTableRowProps) => {
    const {
      id: sys_id,
      inc_url,
      proc_type,
      processing_id,
      error_level,
      error_code: parsedErrorCodes,
      error_message,
      latest_occurrence,
      state,
      assigned_to,
      comments,
      logfile_keys,

      proc_name: process_name,
      site,
      fac: facility,
    } = errorInfo;

    const errorId = String(sys_id);
    const pipelineId: PipelineIdentifier = { process_name, site, facility };

    const { handleResolveError } = useResolveError({ errorType: ErrorHistoryType.Pipeline, onErrorResolved });

    const rowDataList: ReactNode[] = [
      proc_type,
      processing_id,
      capitalize(error_level),
      <pre key="rowDataList-error_code" title={parsedErrorCodes}>
        {parsedErrorCodes}
      </pre>,
      <LongText disabled={disabled} key="rowDataList-error_message" text={error_message} />,
      latest_occurrence,
      state,
      assigned_to,
      <LongText disabled={disabled} key="rowDataList-comments" text={comments} />,
      <ActionsCell
        key="rowDataList-actions"
        disabled={disabled}
        state={state}
        showErrorHistory={!isErrorHistory}
        inc_url={!isErrorHistory ? inc_url : null}
        sys_id={sys_id}
        logfileKeys={logfile_keys}
        procType={proc_type}
        processingId={processing_id}
        pipelineId={pipelineId}
        resolveErrorHandler={(comment) => handleResolveError({ errorId, comment })}
      />,
    ];

    const headCells = ErrorModalHeadCells[TableType.PipelineError] ?? [];
    const columnKeys = headCells.map((header) => header.id);
    const newEntries = zip(columnKeys, rowDataList);

    return (
      <>
        {newEntries.map(([_key, value], idx) => (
          <EnhancedErrorTableCell
            key={idx}
            columnKey={_key as keyof PipelineLogError}
            customPropsSelector={customPropsSelector}
            headCells={headCells}
            columnIndex={idx}
            firstColumnProps={firstColumnProps}
            value={value}
          />
        ))}
      </>
    );
  }
);

export default PipelineErrorEnhancedTableRow;
