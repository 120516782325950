import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { TableCellProps, TableRow } from '@mui/material';

import { capitalize } from 'lodash';

import { useServices } from 'services';

import { ErrorHistoryType } from 'utils/constants';
import { PipelineIdentifier, PipelineLogError } from 'utils/types';

import ActionsCell from './ActionsCell';
import { ErrorTableRowRendererProps } from './ErrorTable';
import LongText from './LongText';
import { StyledTableCell } from './StyledTableCell';

const ColumnProps: Array<TableCellProps | null> = [
  null,
  null,
  null,
  null,
  null,
  // { style: { maxHeight: 400 } },
  // { style: { maxWidth: 200 } },
  null,
  null,
  null,
  null,
  null,
];

type PipelineErrorTableRowProps = ErrorTableRowRendererProps<PipelineLogError>;

const PipelineErrorTableRow = observer(
  ({ errorInfo, isErrorHistory, onErrorResolved, ...props }: PipelineErrorTableRowProps) => {
    const {
      id: sys_id,
      inc_url,
      proc_type,
      processing_id,
      error_level,
      error_code: parsedErrorCodes,
      error_message,
      latest_occurrence,
      state,
      assigned_to,
      comments,
      logfile_keys,

      proc_name: process_name,
      site,
      fac: facility,
    } = errorInfo;

    const pipelineId: PipelineIdentifier = { process_name, site, facility };
    const { processingService } = useServices();

    // TODO: move handlers executed in `ActionLink` into a hook
    const handleResolveError = (comment: string) => {
      processingService
        .resolveError({ errorId: String(sys_id), errorType: ErrorHistoryType.Pipeline, comment, skipLoading: true })
        .then(() => {
          if (onErrorResolved) {
            onErrorResolved();
          }
        });
    };

    const rowDataList: ReactNode[] = [
      proc_type,
      processing_id,
      capitalize(error_level),
      <pre key="rowDataList-error_code" title={parsedErrorCodes}>
        {parsedErrorCodes}
      </pre>,
      <LongText key="rowDataList-error_message" text={error_message} />,
      latest_occurrence,
      state,
      assigned_to,
      <LongText key="rowDataList-comments" text={comments} />,
      <ActionsCell
        key="rowDataList-actions"
        state={state}
        showErrorHistory={!isErrorHistory}
        inc_url={!isErrorHistory ? inc_url : null}
        sys_id={sys_id}
        logfileKeys={logfile_keys}
        procType={proc_type}
        processingId={processing_id}
        pipelineId={pipelineId}
        resolveErrorHandler={handleResolveError}
      />,
    ];

    return (
      <TableRow {...props}>
        {rowDataList.map((content, idx) => (
          <StyledTableCell key={idx} {...ColumnProps[idx]}>
            {content}
          </StyledTableCell>
        ))}
      </TableRow>
    );
  }
);

export default PipelineErrorTableRow;
