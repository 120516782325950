/** @jsxImportSource @emotion/react */
import { ChangeEvent, useCallback, useMemo, useState } from 'react';

import { observer } from 'mobx-react';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Dialog, DialogActions, DialogContent, FormControlLabel, Paper, Stack, Switch } from '@mui/material';

import { useServices } from 'services';

import { RefreshButton } from 'components/common/RefreshButton';
import { TooltipButton } from 'components/common/TooltipButton';
import { Text } from 'components/styles';
import { ErrorModalHeadCells, pipelineErrorIsEditable } from 'components/timeline/processStateButton/table/tableUtils';
import { BulkActionStyles, PipelineErrorBulkActions, useBulkActionHandler } from 'hooks/useBulkActionHandler';
import { useResolveError } from 'hooks/useResolveError';
import { ErrorHistoryType, TableType } from 'utils/constants';
import { PipelineErrorHistoryResponse, PipelineLogError } from 'utils/types';

import { BulkActionFieldsFields } from '../ErrorLogTable/BulkActionFieldsFields';
import ErrorHistoryTableRow from '../ErrorLogTable/ErrorHistoryTableRow';
import ErrorTable, { ARMFlowTableProps } from '../ErrorLogTable/ErrorTable';
import PipelineErrorTableRow from '../ErrorLogTable/PipelineErrorTableRow';
import { SystemErrorEnhancedTableRow } from '../ErrorLogTable/SystemErrorEnhancedTableRow';
import SystemErrorTableRow from '../ErrorLogTable/SystemErrorTableRow';
import { TableHeader } from '../ErrorLogTable/TableHeader';
import { ModalTitle } from './ModalTitle';
import { ModalDialogProps } from './props';
import { useModalStyles } from './styles';

export const GeneralProcessErrorsModal = observer((props: ModalDialogProps) => {
  const classes = useModalStyles(props);
  const { modalInfo, onModalClose } = props;
  const { id: modalId, title, subtitle, errorTableType, customModalData } = modalInfo;

  const { processingService } = useServices();
  const { pipelineErrorData, systemErrorData } = processingService;

  const [isLoading, setIsLoading] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);
  const [showAllErrors, setShowAllErrors] = useState<boolean>(false);

  const refreshWithLoading = async (refreshFunc: () => void) => {
    setIsLoading(true);
    await refreshFunc();
    setIsLoading(false);
  };

  const handleRefreshSystemErrors = useCallback(
    (openErrorsOnly: boolean = !showAllErrors) =>
      refreshWithLoading(() => processingService.fetchSystemErrors({ skipLoading: true, openErrorsOnly })),
    [processingService, showAllErrors]
  );

  const [
    RowRenderer,
    tableHeaders,
    errorList,
    enhancedTableHeaders,
    onErrorResolved,
    EnhancedTableRowRenderer,
    additionalErrorTableProps,
  ] = useMemo(() => {
    switch (errorTableType) {
      case TableType.ErrorHistory:
        // TODO: (2024/06/25 Elvis) This case is never reached due to rendering `PipelineErrorHistoryModal` in `ModalsView`. Bring in tabs and custom adornment renderers to reduce duplicate code
        return [
          PipelineErrorTableRow,
          TableHeader[errorTableType],
          pipelineErrorData?.errorList,
          ErrorModalHeadCells[errorTableType],
        ];

      case TableType.PipelineError:
        // TODO: (2024/07/09 Elvis) This case made obsolete now that ModalsView renders `PipelineErrorHistoryModal` for pipeline errors (which also renders `PipelineErrorTableRow`)
        return [
          PipelineErrorTableRow,
          TableHeader[errorTableType],
          pipelineErrorData?.errorList,
          ErrorModalHeadCells[errorTableType],
        ];

      case TableType.SystemErrors: {
        const errorTableProps: Partial<ARMFlowTableProps<PipelineLogError>> = {
          defaultSort: {
            sortBy: 'latest_occurrence',
            sortDirection: 'desc',
          },
          editableRow: {
            isEditableFunc: pipelineErrorIsEditable,
            readOnlyText: 'Resolved errors are not selectable',
          },
        };

        return [
          SystemErrorTableRow,
          TableHeader[errorTableType],
          systemErrorData?.errorList,
          ErrorModalHeadCells[errorTableType],
          handleRefreshSystemErrors,
          SystemErrorEnhancedTableRow,
          errorTableProps,
        ];
      }

      default:
        return [ErrorHistoryTableRow, [], null, undefined];
    }
  }, [errorTableType, handleRefreshSystemErrors, pipelineErrorData?.errorList, systemErrorData?.errorList]);

  // Bulk-Action variables and handlers
  const errorType = errorTableType === TableType.SystemErrors ? ErrorHistoryType.System : ErrorHistoryType.Pipeline;
  const { handleBulkResolveError } = useResolveError({
    errorType: errorType,
    onErrorResolved,
  });
  const { bulkActionTitleCss, handleClearSelectedRows, ...actionHandlerProps } = useBulkActionHandler({
    actions: PipelineErrorBulkActions,
    onBulkAction: ({ text, identifiers }) => handleBulkResolveError({ errorIds: identifiers, comment: text }),
  });
  const { selectedRows: selected, handleSetSelectedRows: setSelected, disableActionSelect } = actionHandlerProps;

  const handleToggleShowAllSystemErrors = useCallback(
    (_: ChangeEvent<HTMLInputElement>, fetchAllErrors: boolean) => {
      setShowAllErrors(fetchAllErrors);
      handleRefreshSystemErrors(!fetchAllErrors); // Need to pass `openErrorsOnly` flag to avoid using memoized `showAllErrors` when refreshing
      handleClearSelectedRows();
    },
    [handleClearSelectedRows, handleRefreshSystemErrors]
  );

  const TitleAdornmentComponent = useMemo(() => {
    switch (errorTableType) {
      case TableType.ErrorHistory: {
        if (!customModalData) return null;

        const { inc_url } = customModalData as Pick<PipelineErrorHistoryResponse, 'inc_url'>;
        if (!inc_url) return null;

        return (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <span>
              <Button href={inc_url} target="_blank" endIcon={<OpenInNewIcon />} style={{ textTransform: 'unset' }}>
                View INC
              </Button>
            </span>
          </Stack>
        );
      }

      case TableType.SystemErrors: {
        return (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'end'}>
            <FormControlLabel
              control={<Switch onChange={handleToggleShowAllSystemErrors} checked={showAllErrors} />}
              label="Show All Errors"
            />
            <RefreshButton tooltipText="Refresh table" placement="top" onClick={() => handleRefreshSystemErrors()} />
            <TooltipButton
              placement="top"
              onClick={() => setFullScreen(!fullScreen)}
              tooltipText={fullScreen ? 'Minimize' : 'Maximize'}
              Icon={fullScreen ? FullscreenExitIcon : FullscreenIcon}
            />
          </Stack>
        );
      }

      default:
        return null;
    }
  }, [
    customModalData,
    errorTableType,
    fullScreen,
    handleRefreshSystemErrors,
    handleToggleShowAllSystemErrors,
    showAllErrors,
  ]);

  return (
    <Dialog
      open={true}
      className={classes.log}
      fullWidth
      fullScreen={fullScreen}
      maxWidth="xl"
      scroll="paper"
      onClose={(e) => onModalClose && onModalClose(modalId)}
    >
      {/* Title */}
      <ModalTitle modalTitle={title} modalSubtitle={subtitle} adornment={TitleAdornmentComponent} />

      {/* Text */}
      <DialogContent>
        <Stack gap=".5rem">
          <ErrorTable
            loading={isLoading}
            tableType={errorTableType ?? TableType.ErrorHistory}
            tableHeaders={tableHeaders}
            errorList={errorList ?? []}
            RowRenderer={RowRenderer as never} // TODO: need to fix type here
            onErrorResolved={onErrorResolved as never}
            newTableHeaders={enhancedTableHeaders}
            EnhancedRowRenderer={EnhancedTableRowRenderer}
            // Props supporting bulk-action interactions
            {...additionalErrorTableProps}
            disableTable={actionHandlerProps.isSubmitting}
            selectedRowsState={{
              selectedRows: selected,
              setSelectedRows: setSelected,
            }}
          />

          {/* Error Actions */}
          {errorTableType === TableType.SystemErrors && (
            <Stack css={BulkActionStyles.bulkActionSection}>
              <Text css={bulkActionTitleCss}>
                Bulk Actions {!disableActionSelect && `(${selected.length} selected)`}
              </Text>

              <Paper elevation={2}>
                <div
                  css={[
                    BulkActionStyles.bulkActionField,
                    disableActionSelect ? BulkActionStyles.formDisabled : BulkActionStyles.formEnabled,
                  ]}
                >
                  <BulkActionFieldsFields {...actionHandlerProps} />
                </div>
              </Paper>
            </Stack>
          )}
        </Stack>
      </DialogContent>

      {/* Action Buttons */}
      <DialogActions>
        <Button onClick={(e) => onModalClose && onModalClose(modalId)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
});
