import { PopoverProps, TextFieldProps } from '@mui/material';

import { DateTime, Settings } from 'luxon';

import { FiltersInterface } from 'models/Filters';

import { LogViewerData, SettingsVisualToggle } from './types';

/**
 * IMPORTANT: Set default luxon timezone to UTC
 *
 * It's important this is done at the top of this file since the setting doesn't modify existing instances.
 * Thus, any constants (which are pre-compiled at build-time) will use the local system time if default isn't overridden
 */
Settings.defaultZone = 'utc';
// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/64995
declare module 'luxon' {
  interface TSSettings {
    throwOnInvalid: true;
  }
}
/************************************************ */

/** @deprecated Remove 'mode' concept */
export enum Mode {
  EVENT = 'event-driven',
  MANUAL = 'manual',
  REPROCESSING = 'reprocessing',
}

export enum RunMode {
  AUTO = 1,
  MANUAL = 2,
  CRITICAL_PAUSE = 3,
  DEPRECATED = 4,
  LOCKED = 5,
  // END_OF_LIFE = 6, // TODO: RunMode.END_OF_LIFE state doesn't currently exist
}

/** State of error to match ServiceNow states */
export enum PipelineErrorState {
  NEW = 'New',
  ACTIVE = 'Active',
  AWAITING_USER_INFO = 'Awaiting User Info',
  RESOLVED = 'Resolved',
  CLOSED = 'Closed',
}

export const RunModeToLabel: Record<RunMode, string> = {
  [RunMode.AUTO]: 'Auto',
  [RunMode.MANUAL]: 'Manual',
  [RunMode.CRITICAL_PAUSE]: 'Critical Pause',
  [RunMode.DEPRECATED]: 'Deprecated',
  [RunMode.LOCKED]: 'Locked',
  // [RunMode.END_OF_LIFE]: 'End of Life', TODO: RunMode.END_OF_LIFE state doesn't currently exist
};

export const RunModeSelectionOptions: Record<string, RunMode> = {
  Auto: RunMode.AUTO,
  Manual: RunMode.MANUAL,
  'Critical Pause': RunMode.CRITICAL_PAUSE,
  Deprecated: RunMode.DEPRECATED,
  Locked: RunMode.LOCKED,
  // 'End of Life': RunMode.END_OF_LIFE, TODO: RunMode.END_OF_LIFE state doesn't currently exist
};

export enum RunType {
  AUTO = 0,
  MANUAL = 1,
  REPROCESSING = 2,
  GAP_FILL = 3,
}

export enum AppState {
  IDLE = 0,
  LOADING = 1,
  ERROR = 2,
  PIPELINE_ERROR = 3,
  GENERIC = 4,
  PIPELINE_ERROR_HISTORY = 5,
  SYSTEM_ERRORS = 6,
  PROCESS_STATES = 7,
  MODAL, // todo: This will determine whether to display modal outside of loading/error
}

// TODO: Eventually use this to define modal type in ModalsView
export enum ModalType {
  Loading,
  Error,
  Generic,
  ErrorHistory,
}

export enum SettingsVisualToggleKey {
  AUTO_REFRESH = 'auto-refresh',
  ARCHIVE = 'archived',
  REPROCESSED = 'reprocessed',
  BLUE_SUCCESS = 'blue-success',
}

export enum ProcessingServiceModalIds {
  LOADING = 'processing-loading',
  ERROR = 'processing-error',
}

export enum ARMFlowForms {
  RUN_BUNDLER = 'run-bundler',
  RUN_PROCESS = 'run-process',
  RELEASE_DATA = 'release-data',
  PRUNE_DATA = 'prune-data',
}

export enum FilterQueryParams {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  FILTER = 'filter',
}

export enum FileDownloadTypes {
  PROCESS_LOG = 'process-log',
  COMLOG = 'comlog',
  REALTIME_PROCESS_LOG = 'realtime-process-log',
  SLURM_LOG = 'slurm-log',
  SLURM_BATCH_SCRIPT = 'slurm-batch-script',
  AIRFLOW_PROCESS_CONFIG = 'airflow-process-config',
  AIRFLOW_LOG = 'airflow-log',
  BUNDLE_LOG = 'bundler-log', // TODO: bundler not yet implemented
}

export const LogTypeLabel: Record<FileDownloadTypes, string> = {
  [FileDownloadTypes.PROCESS_LOG]: 'Process Log',
  [FileDownloadTypes.COMLOG]: 'Comlog',
  [FileDownloadTypes.REALTIME_PROCESS_LOG]: 'Realtime Process Log',
  [FileDownloadTypes.SLURM_LOG]: 'Slurm Log',
  [FileDownloadTypes.SLURM_BATCH_SCRIPT]: 'Slurm Batch Script',
  [FileDownloadTypes.AIRFLOW_PROCESS_CONFIG]: 'ARMFlow Process Config',
  [FileDownloadTypes.AIRFLOW_LOG]: 'Airflow Log',
  [FileDownloadTypes.BUNDLE_LOG]: 'Bundler Log',
};

export enum ErrorHistoryType {
  Pipeline = 'pipeline',
  System = 'system',
}

export enum ActionMenuItems {
  // General
  REFRESH = 'refresh',
  INFO = 'info',
  ERROR_HISTORY = 'view-error-history',
  PIPELINE_PROCESS_STATES = 'pipeline-process-states',

  // File viewing
  VIEW_PROCESS_LOG = 'view_process_log',
  VIEW_COLLECTIONS_LOG = 'view_collections_log',
  VIEW_SLURM_LOG = 'view_slurm_log',
  VIEW_SLURM_BATCH_SCRIPT = 'view_slurm_batch_script',
  VIEW_ARMFLOW_PROCESS_CONFIG = 'view_armflow_process_config',
  VIEW_BUNDLE_LOG = 'view_bundle_log',

  // Actions
  RUN_BUNDLER = 'run-bundler',
  ADD_COMMENT = 'add-comment',
  RUN_PROCESS = 'run-process',
  PRUNE_DATA = 'prune-data',
  RELEASE_DATA = 'release-data',
}

// TODO: move API to it's own file and create 'definitions' directory for types and constants
export enum API {
  TIMELINES = '/armflow/ui/query/timeline',
  QUERY_ERROR = '/armflow/ui/errors/pipeline',
  RESOLVE_ERROR = '/armflow/ui/error',
  RESOLVE_ERROR_BULK = '/armflow/ui/error/bulk',
  PROCESS_STATES = '/armflow/ui/pipeline/process_states',
  LOCATIONS = '/armflow/ui/locations',
  EXISTING_PROCESSES = '/armflow/ui/system/processes',
  USERS = '/armflow/ui/users',
  SYSTEM_ERROR = '/armflow/ui/errors/system/flag',
  SYSTEM_ERROR_HISTORY = '/armflow/ui/errors/system',
  GET_EWO_URL = '/armflow/ui/get_ewo_url',
  RUNNABLE_DATES = '/armflow/ui/pipeline/run_dates',
  PRUNABLE_DATES = '/armflow/ui/pipeline/prunable_dates',
  RUN_PROCESS = '/armflow/ui/pipeline/run_ingest_vap',
  STOP_PROCESS = '/armflow/ui/pipeline/stop_ingest_vap',
  RELEASE_DATA = '/armflow/ui/pipeline/release_data',
  PRUNE_DATA = '/armflow/ui/pipeline/prune_data',
  RUN_BUNDLER = '/armflow/ui/pipeline/run_bundler',
  ARMFLOW_FILE_DOWNLOAD = '/armflow/ui/file/download',
  FILES_TO_RELEASE = '/armflow/ui/pipeline/non_released_files',
  NON_RELEASED_PROCESSING_IDS = '/armflow/ui/pipeline/non_released_processing_ids',
  ME = '/armflow/ui/me',
  LOGOUT = '/logout/',
  VALIDATE_RUN_PROCESS = '/armflow/ui/pipeline/validate/run_ingest_vap',
  VALIDATE_RELEASE_DATA = '/armflow/ui/pipeline/validate/release_data',
  VALIDATE_PRUNE_DATA = '/armflow/ui/pipeline/validate/prune_data',
  CSRF_REFRESH = '/armflow/ui/refresh_token',
  PIPELINE_RUN_INFO = '/armflow/ui/pipeline/run_info',
  CURRENT_PIPELINE_RUNS = '/armflow/ui/pipeline/current_runs',
}

export enum CsrfErrors {
  MISSING = 'The CSRF token is missing.',
  EXPIRED = 'The CSRF token has expired.',
}

export const DEFAULT_NUMBER_DAYS_TO_FILTER = 14;
// export const DEFAULT_TIMELINE_END_DATE: DateTime | null = null;
// export const DEFAULT_TIMELINE_START_DATE: DateTime | null = null;
export const DEFAULT_TIMELINE_END_DATE: DateTime = DateTime.now().startOf('day'); // Should already be set to UTC based on global declaration at top of file
export const DEFAULT_TIMELINE_START_DATE: DateTime = DEFAULT_TIMELINE_END_DATE.minus({ weeks: 2 }); // subtract two weeks
export const PastDaysFieldMin = 1;
export const PastDaysFieldMax = 999;
export const PastDaysFieldEndDateOffset = 1;

// export const DEFAULT_TIMELINE_END_DATE: Date = DateTime.fromISO('2020-06-01').toJSDate();
// export const DEFAULT_TIMELINE_START_DATE: Date = DateTime.fromISO('2019-12-01').toJSDate();
export const PROCESS_INTERVAL_START_COLUMN_INDEX = 1;

// export const MIN_DATE: Date = new Date('01-01-1900');
// export const MAX_DATE: Date = new Date('12-31-2022');
export const MIN_DATE: DateTime = DateTime.fromISO('1990-01-01');
export const MAX_DATE: DateTime = DateTime.fromISO('2100-12-31'); // TODO: make max date today

// export const CommonDatePickerProps: Partial<DatePickerProps<DateTime>> = {
export const CommonDatePickerProps = {
  minDate: MIN_DATE,
  maxDate: MAX_DATE,
  inputFormat: 'yyyy-MM-dd',
  mask: '____-__-__',
};

export const CommonTextFieldProps = (params: TextFieldProps): TextFieldProps => {
  return {
    ...params,
    InputLabelProps: {
      shrink: true,
      ...params.InputLabelProps,
    },
    inputProps: {
      ...params.inputProps,
      placeholder: 'YYYY-MM-DD',
    },
  };
};

export const AUTO_REFRESH_DELAY: number = 10000; // in milliseconds
export const LiveLogDelay = 3000; // in milliseconds
export const DRAWER_WIDTH: number = 355;
export const DRAWER_CLOSED_WIDTH: number = 56;

export const MINIMUM_LABEL_WIDTH: number = 300;
export const DEFAULT_LABEL_WIDTH: number = 350;

export const SORT_LABEL_WIDTH: number = 600;

export const MIN_ZOOM: number = 0.1;
export const MAX_ZOOM: number = 8.0;
export const ZOOM_THRESHOLD: number = 0.2;
export const DATA_ZOOM_THRESHOLD: number = 3;
export const DATE_VISIBILITY_ZOOM_THRESHOLD: number = 0.3;

export const REMAINING_HEIGHT: string = `calc(100vh - ${64 * 2}px)`;
export const ROW_HEIGHT: number = 40;
export const DATE_ROW_HEIGHT: number = 50;
export const BASE_BOX_WIDTH = 10;

export const FILTER_PANEL_COLOR: string = '#424242';
export const DISABLE_COLOR: string = '#666666'; // a dark-ish grey

export const AUTO_TOUCH_FORM_FIELD = true;
export const MISSING_DATA_FALLBACK_TEXT: string = '- - -';
export const CONFIRM_FORM_FALLBACK_TEXT: string = '[not set]';

export enum SortOrder { // TODO: rename to ProcessListSortBy
  TYPE = 'type',
  NAME = 'name',
  LOCATION = 'location',
}

export enum ProcessType {
  INGEST = 'Ingest',
  VAP = 'VAP',
  BUNDLE = 'Bundle',
  Collection = 'Collection',
  TimeCheck = 'TimeCheck',
  ReleaseData = 'ReleaseData',

  // Adding other process types (as seen in backend codebase)
  // Rename = "Rename"
  // SneakerNet = 'Sneakernet',
  // External = 'External',
  // SummaryQc = 'SummaryQC',
  // IngestOrVap = 'Ingest/VAP',
  // InventoryUpdate = 'InventoryUpdate',
  // ArmflowSystem = 'ARMFlow System',
}

export const ProcessTypeLabel: Record<ProcessType, string> = {
  [ProcessType.INGEST]: ProcessType.INGEST,
  [ProcessType.VAP]: ProcessType.VAP,
  [ProcessType.BUNDLE]: ProcessType.BUNDLE,
  [ProcessType.Collection]: ProcessType.Collection,
  [ProcessType.TimeCheck]: 'Time Check',
  [ProcessType.ReleaseData]: 'Release Data',
};

/** @todo Consider consolidating values with `ProcessType` */
// export enum ErrorHistoryTypes {
//   Collection = 'Collection',
//   Bundler = 'Bundler',
//   Ingest = 'Ingest',
//   VAP = 'VAP',
// }

export const DEFAULT_PROCESS_TYPES = new Set([ProcessType.INGEST, ProcessType.VAP]);

export const EMPTY_LOG_VIEWER_DATA: LogViewerData = {
  pipelineId: {
    process_name: '',
    site: '',
    facility: '',
  },
  text: '',
};

export enum RefreshReason {
  Manual = 'manual',
  Programmatic = 'programmatic',
  AutoRefresh = 'auto-refresh',
}

export enum TableType {
  PipelineError = 'pipeline-error',
  SystemErrors = 'system-errors',
  ErrorHistory = 'error-history',
}

export enum PipelineErrorLevel {
  ERROR = 1,
  WARNING = 2,
  NOINPUT = 3,
  ASSIGNED = 4,
  RESOLVED = 5, // NOTE: not currently rendered in frontend
  INFO = 6,
}

export enum ProcessStatus {
  SUCCESS = 1,
  ERROR = 2,
  WARNING = 4,
  NOINPUT = 8,
  REPROCESSED = 16,
  DID_NOT_RUN = 0,
}

export enum ProcessStatusLabel {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  NOINPUT = 'NO_INPUT_DATA',
  RUNNING = 'RUNNING',
}

export enum ProcessErrorCodes {
  AIRFLOW = 'airflow',
  SLURM = 'slurm',
  COLLECTION = 'collection',
  BUNDLE = 'bundle',
  ADI_SYSTEM = 'adi.system',
  ADI_SYSTEM_IO = 'adi.system.io',
  ADI_SIGNAL = 'adi.signal',
  ADI_DB = 'adi.db',
  ADI_PROCESSING = 'adi.processing',
  ADI_PROCESSING_OTHER = 'adi.processing.other',
  ADI_NO_INPUT = 'adi.no_input',
}

export enum DataStatusLabels {
  GOOD = 'All outputs created and passed QC checks',
  BAD = 'All outputs failed QC checks',
  SOME_BAD = 'Some outputs failed QC checks',
  SOME_MISSING = 'Some outputs are missing',
  MISSING = 'All outputs are missing',
  RELEASING = ' Release request submitted',
  ARCHIVED = 'All files released to archive',
  SOME_ARCHIVED = 'Some files released to archive',
}

export enum ArchivedState {
  NOT_ARCHIVED = 0,
  ARCHIVED = 1,
  SOME_ARCHIVED = 2,
}

export enum FileState {
  BAD = 0,
  SOME_BAD = 1,
  GOOD = 2,
  MISSING = 3,
  SOME_MISSING = 4,
}

export enum ReleaseState {
  NOT_RELEASING = 0,
  RELEASING = 1,
}

/** Popover-related settings */
export const CommonPopoverProps: Omit<PopoverProps, 'open'> = {
  slotProps: { paper: { sx: { marginTop: '.5rem' } } },
};
enum AnchorPointPresets {
  TOP_LEFT = 'TopLeft',
  BOTTOM = 'Bottom',
  BOTTOM_LEFT = 'BottomLeft',
  BOTTOM_RIGHT = 'BottomRight',
  RIGHT = 'Right',
  RIGHT_TOP = 'RightTop',
  LEFT = 'Left',
}
export type PopoverAnchorPointProps = Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin'>;
export const AnchorPoints: Record<AnchorPointPresets, PopoverAnchorPointProps> = {
  [AnchorPointPresets.TOP_LEFT]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  },
  [AnchorPointPresets.BOTTOM]: {
    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
    transformOrigin: { vertical: 'top', horizontal: 'center' },
  },
  [AnchorPointPresets.BOTTOM_LEFT]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [AnchorPointPresets.BOTTOM_RIGHT]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  [AnchorPointPresets.RIGHT]: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
  },
  [AnchorPointPresets.RIGHT_TOP]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [AnchorPointPresets.LEFT]: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
  },
};

/** Preferences Menu-related settings */
export const VisualToggleDefaults: Record<SettingsVisualToggleKey, SettingsVisualToggle> = {
  [SettingsVisualToggleKey.AUTO_REFRESH]: { active: true, label: 'Auto-Refresh Pipeline Data' },
  [SettingsVisualToggleKey.ARCHIVE]: { active: true, label: 'Show data status' },
  [SettingsVisualToggleKey.REPROCESSED]: { active: true, label: 'Show reprocessing status' },
  [SettingsVisualToggleKey.BLUE_SUCCESS]: { active: false, label: 'Blue Timeline Theme' },
};

/** Filter Panel */
export const FiltersModelDefaults: Omit<FiltersInterface, 'mode'> = {
  // TODO: remove 'mode' concept
  processType: new Set(DEFAULT_PROCESS_TYPES),
  processName: new Set<string>(),
  location: new Set<string>(),
  user: new Set<string>(),
  reprocessedOnly: false,
  unarchivedOnly: false,
  pipelineErrorsOnly: false,
  showRegularRuns: true,
  showReprocessingRuns: true,
  processStatus: new Set([
    ProcessStatus.SUCCESS,
    ProcessStatus.WARNING,
    ProcessStatus.NOINPUT,
    ProcessStatus.ERROR,
    ProcessStatus.REPROCESSED,
    ProcessStatus.DID_NOT_RUN,
  ]),

  useLastNumDaysField: true,
  lastNumDays: DEFAULT_NUMBER_DAYS_TO_FILTER,
  timelineStartDate: DEFAULT_TIMELINE_START_DATE,
  timelineEndDate: DEFAULT_TIMELINE_END_DATE,
};

/** HTTP */
export enum HttpErrorStatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  CSRF_ERROR = 456,
  INTERNAL_SERVER_ERROR = 500,
}
