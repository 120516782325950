import React from 'react';

import { observer } from 'mobx-react';

import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';

import { ActionLink } from 'components/common/ActionLink';
import { UseStyles } from 'styles/utilityTypes';
import { AnchorPoints } from 'utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  longText: {
    // whiteSpace: 'pre-wrap',
  },

  popover: {
    whiteSpace: 'pre-wrap',
    fontSize: theme.typography.body2.fontSize,
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

export interface LongTextProps extends UseStyles<typeof useStyles> {
  className?: string;

  text: string;
  expansionLabel?: string;
  disabled?: boolean;
}

const LongText = observer((props: LongTextProps): React.ReactElement | null => {
  const { className, text, expansionLabel = 'View All', disabled } = props;
  const classes = useStyles(props);
  if (!text) return null;

  return (
    <div className={clsx(classes.root, className)}>
      <PopupState variant="popover" popupId="longText">
        {(popupState) => (
          <span>
            <code className={classes.longText}>{text}</code>
            <div>
              <ActionLink disabled={disabled} {...bindTrigger(popupState)}>
                {expansionLabel}
              </ActionLink>
              {/* <ActionLink>
                <MoreHorizIcon />
              </ActionLink> */}
            </div>

            <Popover
              {...bindPopover(popupState)}
              {...AnchorPoints.BottomLeft}
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
            >
              <code>{text}</code>
            </Popover>
          </span>
        )}
      </PopupState>
    </div>
  );
});

export default LongText;
