/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { observer } from 'mobx-react';

import { capitalize, zip } from 'lodash';

import { ErrorModalHeadCells } from 'components/timeline/processStateButton/table/tableUtils';
import { BaseEnhancedTableRowRendererProps } from 'components/timeline/processStateButton/table/types';
import { useResolveError } from 'hooks/useResolveError';
import { ErrorHistoryType, TableType } from 'utils/constants';
import { PipelineIdentifier, PipelineLogError } from 'utils/types';

import ActionsCell from './ActionsCell';
import { EnhancedErrorTableCell } from './EnhancedErrorTableCell';
import { ErrorTableRowRendererMiscProps } from './ErrorTable';
import LongText from './LongText';

type SystemErrorEnhancedTableRowProps = ErrorTableRowRendererMiscProps &
  BaseEnhancedTableRowRendererProps<PipelineLogError>;

/** @todo Very similar code as `PipelineErrorEnhancedTableRow`. Find way to consolidate (maybe use `TableHeader` somehow) */
export const SystemErrorEnhancedTableRow = observer(
  ({
    onErrorResolved,

    rowData: errorInfo,
    entries,
    customPropsSelector,
    firstColumnProps = {},

    ...props
  }: SystemErrorEnhancedTableRowProps) => {
    const {
      id: sys_id,
      proc_type,
      processing_id,
      error_level,
      error_code: parsedErrorCodes,
      error_message,
      latest_occurrence,
      state,
      logfile_keys,

      proc_name: process_name,
      site,
      fac: facility,
    } = errorInfo;

    const errorId = String(sys_id);
    const pipelineId: PipelineIdentifier = { process_name, site, facility };

    const { handleResolveError } = useResolveError({ errorType: ErrorHistoryType.System, onErrorResolved });

    const rowDataList: ReactNode[] = [
      proc_type,
      capitalize(error_level),
      <pre key="rowDataList-error_code" title={parsedErrorCodes}>
        {parsedErrorCodes}
      </pre>,
      <LongText key="rowDataList-error_message" text={error_message} />,
      latest_occurrence,
      state,
      <ActionsCell
        key="rowDataList-actions"
        state={state}
        showErrorHistory={false}
        inc_url={null}
        sys_id={sys_id}
        logfileKeys={logfile_keys}
        procType={proc_type}
        processingId={processing_id}
        pipelineId={pipelineId}
        resolveErrorHandler={(comment) => handleResolveError({ errorId, comment })}
      />,
    ];

    const headCells = ErrorModalHeadCells[TableType.SystemErrors] ?? [];
    const columnKeys = headCells.map((header) => header.id);
    const newEntries = zip(columnKeys, rowDataList);

    return (
      <>
        {newEntries.map(([_key, value], idx) => {
          return (
            <EnhancedErrorTableCell
              key={idx}
              columnKey={_key as keyof PipelineLogError}
              customPropsSelector={customPropsSelector}
              headCells={headCells}
              columnIndex={idx}
              firstColumnProps={firstColumnProps}
              value={value}
            />
          );
        })}
      </>
    );
  }
);
